import {Outlet} from 'react-router-dom';

// mui
import {Container} from '@mui/material';
import {ErrorWrapper, Header, MyAccountMenu} from "@eidsivabredband/react-component-ui-package";
import React, {ErrorInfo, useContext} from "react";
import {EbAuthenticatedAppContext, useAuthentication} from "@eidsivabredband/app-framework";

interface LogMessage {
    message: string;
    sourceClass?: string;
    sourceMethod?: string;
    sourceLineNumber?: string;
}

type LogLevel = 'Info' | 'Error' | 'Warning';
export const Layout = () => {
    const {context} = useContext(EbAuthenticatedAppContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleReset = () => {
        // reset the application state (e.g. reload or reset state)
    };

    const handleError = (error: Error, info: ErrorInfo) => {
        const {message, stack} = error;
        if (stack) {
            const logMessage: LogMessage = {message: stack};
            const logLevel: LogLevel = 'Error';
            context.restApiClient.buildRequest(`/api/log/${logLevel}`).serializeToJsonContent(logMessage).putAsync()
                .then((response: any) => {
                    console.log(response);
                });
        }
    };

    const client = useAuthentication();
    const handleSignIn = () => {
        if (!context) return;
        client.login(context.authConfig?.scopes || []);
    };

    const handleSignOut = () => {
        if (!context) return;
        client.logout();
    };

    return (
        <>
            <Header withAppDrawer={true}>
                <MyAccountMenu
                    isAuthenticated={client.isUserAuthenticated()}
                    firstName={context.userInfo?.givenName}
                    lastName={context.userInfo?.surname}
                    email={context.userInfo?.emailAddress}
                    handleSignIn={handleSignIn}
                    handleSignOut={handleSignOut}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                />
            </Header>
            <Container sx={{mt: 5, mb: 5}} maxWidth="xl">
                <ErrorWrapper handleError={(error, info) => handleError(error, info)} handleReset={handleReset}>
                    <Outlet/>
                </ErrorWrapper>
            </Container>
        </>
    )
};
    
